import React, { useState, useEffect, useRef } from 'react';

import MUIDataTable from 'mui-datatables';
import { Navigate, useNavigate, Link as RouterLink } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  IconButton,
  CardContent,
  Grid,
  Box
} from '@mui/material';

import { getDownloadURL, deleteObject, getStorage, ref, listAll } from "firebase/storage";

import {isAuthenticated, roleId, hostId} from '../../../utils/session'
import LoadingModal from '../../../components/settings/LoadingModal';

import DialogModal from '../../../components/settings/DialogModal';
import { showError, showSucess } from '../../../utils/toast';
import {apiUrl, apiCallSecureGet, apiPostSecure, apiCallSecureDelete} from '../../../utils/api'
import AddScheduleModal from './modals'
import CallCard from './cards'

// components
import Page from '../../../components/Page';

const RecordPage = () => {


  const [loadData, setLoadData] = useState(true);
  const [rows, setRows] = useState([]);

  const [deleteData, setDeleteData] = useState(null)

  const editData = useRef(null)
  const infoData = useRef(null)
  const locationList = useRef([])

  const [showDataAdd, setShowDataAdd] = useState(false)
  const [loadLocations, setLocations] = useState(true);

  const [loadingTitle, setLoadingTitle] = useState(null);

  const roleIdName = useRef(roleId());

  const storage = useRef(getStorage());

  const onCancelDelete = () => {
    setDeleteData(null);
  }

  const onDownload = (data) => {
    getDownloadURL(ref(storage.current, data.fullPath))
      .then((url) => {
        window.open(url)
      })
      .catch((error) => {
        showError(error)
      });
  }

  const onDeleteShow = (data) => {
    console.log('onDeleteShow', data);
    setDeleteData(data)
  }

  const onDelete = (data) => {
    const file = data.name;
    setDeleteData(null);
    apiCallSecureDelete(
      apiUrl.deleteFile, file,
      (response) => {
          showSucess(response.msg);
          setLoadData(true);
      },
      (error) => {
        showError(error||'Error');
        console.log('loadBusses', error||'Error');
      }
    )

  }

  useEffect(() => {

    if(!loadData) {
      return
    }
    setLoadData(false)

    const listRef = ref(storage.current, `sbs-calls/${hostId()}`);

    listAll(listRef)
      .then((res) => {
        res.prefixes.forEach((folderRef) => {
           console.log('prefixes: ', folderRef.name)
        });

        const itemList = res.items.map((call) => (
          <Grid item xs={12} md={6} lg={6}>
              <CallCard data={call} role={roleIdName} onDownload={onDownload} onDelete={onDeleteShow}/>
          </Grid>
        ));
        setRows(itemList)

      }).catch((error) => {
        console.log('error: ', error)
      });


  }, [loadData])


  const onAddScheduleListener = (status, message) => {
    if(status === 1) {
      setLoadingTitle(null);
      showSucess(message);
      setLoadData(true)
    }else if(status === 2) {
      setLoadingTitle(null);
      showError(message)
    }else {
      setLoadingTitle(message);
    }
  }

  const GetHeader = () => {
      return(
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant='h6' color="text.secondary" >
            Call Records
          </Typography>
        </Stack>
      )
  }


  return (
      <Page title="Schedules">
      <Container>
        <LoadingModal
            title={loadingTitle} />
        <DialogModal
          state={deleteData}
          title='Delete Record!'
          message={`Do you want to delete this call record?`}
          handleclose={onCancelDelete}
          addclickhandler={onDelete}
          buttonlabel="Yes, Delete"
        />   
        <AddScheduleModal
          show={showDataAdd} change={setShowDataAdd} state={editData} locationList={locationList} onSaveChange={onAddScheduleListener} onError={showError} />
        <Card sx={{p:2}} >
          <GetHeader />
        </Card>
        <Grid container spacing={2} sx={{mt: 2}}>
          {rows}          
        </Grid>
      </Container>
    </Page>
    );
}

export default RecordPage;
